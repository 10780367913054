import {Link} from "gatsby";
import PropTypes from "prop-types";
import React, {useState} from "react";
import logo from "../images/logo.svg";
import logoIcon from "../images/icon.png";
import menuIcon from "../images/menu-icon.png";
import right from "../images/right-orange.svg";

const NavItem = ({children}) => (
  <li className="w-full md:w-auto flex items-center justify-between md:justify-center py-2 md:py-0 md:px-2 text-2xl font-bold md:text-sm md:font-normal cursor-pointer hover:text-primary whitespace-no-wrap">
    {children}

    <span className="float-right md:hidden">
      {" "}
      <img src={right} alt="navigate" className="w-6 h-6" />
    </span>
  </li>
);
const Header = ({siteTitle}) => {
  const [displayMenu, toggleDisplay] = useState(false);
  return (
    <header className=" md:h-24 flex items-center flex-wrap justify-between md:border-b-2 border-solid border-primary">
      <Link className="flex items-center  md:self-end md:mb-3" to="/">
        <span className="text-2xl font-bold text-primary  my-3  mr-3  md:hidden">
          VentureFriends
        </span>
        <img src={logoIcon} alt="logo" className="w-8 h-8 md:hidden" />
        <img src={logo} alt="logo" className="hidden md:inline" />
      </Link>
      <button className="block w-8 h-6 md:hidden">
        <img
          src={menuIcon}
          onClick={() => toggleDisplay(!displayMenu)}
          alt="logo"
          className="cursor-pointer object-cover"
        />
      </button>
      <div
        className={`w-full md:w-6/12 md:self-end ${
          displayMenu ? `` : "hidden md:block"
        } `}
      >
        <ul className="md:divide md:flex md:mb-3 md:px-6 md:divide-x md:divide-primary md:float-right">
          <Link to="/portfolio">
            <NavItem>Portfolio</NavItem>
          </Link>
          <Link to="/about">
            <NavItem>About us </NavItem>
          </Link>
          <Link to="/team">
            <NavItem>Team </NavItem>
          </Link>
          <Link to="/careers">
            <NavItem>Careers </NavItem>
          </Link>
          <Link to="/blog">
            <NavItem>Blog </NavItem>
          </Link>
          <Link to="/contact">
            <NavItem>Contact </NavItem>
          </Link>
        </ul>
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
