import React, { ReactNode, useEffect, useState } from "react";
import { Link } from "gatsby";
import info from "../images/afm.svg";
const logo = require("../images/small-logo.svg");
const eifLogo = require("../images/eif-logo.jpg");
const espaLogo = require("../images/espa-1420-logo.jpg");
const euLogo = require("../images/flag-eu-erdf-eng.jpg");
const ecLogo = require("../images/ec-logo.svg");
const epanekLogo = require("../images/epanek.png");
const ypourgeioLogo = require("../images/ypourgeio-ependyseis.jpg");
const FooterItemList = ({ children }: { children: ReactNode }) => (
  <ul className="flex flex-col items-center md:items-start md:flex-row md:divide-x divide-primary text-primary md:mb-4">
    {children}
  </ul>
);

const FooterItem = ({
  children,
  first = false,
}: {
  children: ReactNode;
  first?: boolean;
}) => (
  <li
    className={`text-sm ${
      !first ? `md:pl-3` : ``
    } md:pr-3 py-2 md:py-0 hover:font-bold`}
  >
    {children}
  </li>
);

function Footer() {
  return (
    <>
      <footer className="pt-14 md:pt-12 bg-primary bg-opacity-3">
        <div className="mx-6 lg:mx-32 flex md:block flex-col items-center">
          <FooterItemList>
            <FooterItem first={true}>
              <Link to="/portfolio">Portfolio</Link>
            </FooterItem>
            <FooterItem>
              <Link to="/about">About us</Link>
            </FooterItem>
            <FooterItem>
              <Link to="/team">Team</Link>
            </FooterItem>
            <FooterItem>
              <Link to="/careers">Careers</Link>
            </FooterItem>
            <FooterItem>
              <Link to="/blog">Blog</Link>
            </FooterItem>
            <FooterItem>
              <Link to="/contact">Contact</Link>
            </FooterItem>
          </FooterItemList>

          <FooterItemList>
            <FooterItem first={true}>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </FooterItem>
            <FooterItem>
              <Link to="/disclaimer">Disclaimer</Link>
            </FooterItem>
            {/* <FooterItem first={true}>
            <Link to='/'>Cookies Policy</Link>
          </FooterItem> */}
          </FooterItemList>

          <div className="flex flex-wrap items-center md:justify-between justify-center gap-4">
            <div className="flex-initial flex flex-col md:flex-row items-center md:items-end py-6 text-sm text-primary">
              <img className="mb-5 md:mb-0 md:mr-3" src={logo} alt="logo" />
              <span className="text-center">
                © VentureFriends 2020,
                <br className="md:hidden" /> designed by{" "}
                <a
                  href="http://holy.gd"
                  target="_blank"
                  rel="noopenner noreferrer"
                  className="hover:opacity-50"
                >
                  høly
                </a>
              </span>
            </div>

            <div
              className="lg:ml-auto w-full xl:w-8/12 mb-12 md:mb-0 grid lg:grid-cols-6 grid-cols-3 items-center gap-2"
              style={{ mixBlendMode: "multiply" }}
            >
              <img src={epanekLogo} alt="epanekLogo" className="h-12 mx-auto" />
              <img src={eifLogo} alt="eifLogo" className="h-15 mx-auto" />
              <img src={euLogo} alt="euLogo" className="h-15 mx-auto" />
              <img src={ecLogo} alt="euLogo" className="h-15 mx-auto" />
              <img src={espaLogo} alt="espaLogo" className="h-15 mx-auto" />
              <img
                src={ypourgeioLogo}
                alt="ypourgeioLogo"
                className="h-15 mx-auto"
              />
            </div>
          </div>
        </div>
      </footer>
      <div className="py-4">
        <div className="flex items-center">
          <div className="mx-6 lg:mx-32 flex-1 flex items-center text-center space-x-4 justify-between py-4">
            <p>
              Supported by InnovFin Equity, with the financial backing of the
              European Union under Horizon 2020 Financial Instruments and the
              European Fund for Strategic Investments (EFSI) set up under the
              Investment Plan for Europe. The purpose of EFSI is to help support
              financing and implementing productive investments in the European
              Union and to ensure increased access to financing.
            </p>
          </div>
        </div>
        <div className="flex items-center">
          <div className="mx-6 lg:mx-32 flex-1 flex font-extrabold items-center space-x-4 justify-between border-t-2 border-b-2 py-4">
            <p>
              Attention! This investment falls outside AFM supervision. No
              licence and no prospectus required for this activity!
            </p>
            <img className="block" src={info} alt="" width="72" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
