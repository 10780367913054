/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
// import { useStaticQuery, graphql } from "gatsby";

import Header from "./header";
import Footer from "./footer";
// import "./layout.css";
import CookieConsent from "react-cookie-consent";
import { Link } from "gatsby";

function getCookie(name) {
  if (typeof document !== "undefined") {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  } else {
    return undefined;
  }
}
const Layout = ({ children }) => {
  const [isClosed, setClosed] = useState(false);

  const isCookieSet =
    getCookie("gatsby-gdpr-google-analytics") === "false" ||
    getCookie("gatsby-gdpr-google-analytics") === "true";

  const onDone = (accept) => {
    setClosed(true);
    if (accept && typeof window !== "undefined") {
      window.location.reload();
    }
  };
  return (
    <>
      <CookieConsent //styles in styles.css
        location="bottom"
        className="CookieConsent--large-screen"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics"
        enableDeclineButton
        buttonText="Accept"
        declineButtonText="Decline"
        containerClasses="cookie-container"
        contentClasses="cookie-container__text"
        buttonWrapperClasses="cookie-container__button-wrapper"
        buttonClasses="cookie-container__button-wrapper__accept_btn"
        declineButtonClasses="cookie-container__button-wrapper__decline_btn"
        onAccept={() => onDone(true)}
        onDecline={() => onDone()}
      >
        <h4 className="font-bold text-base text-primary mb-4 mobile-only block ">
          We don't do marketing
        </h4>
        <span className="desktop-only inline">
          We don't do marketing. &nbsp;
        </span>
        We only use Google Analytics to ensure the seamless function of the Site
        and enhance its user experience. We also respect your privacy, that's
        why we would like to ask you if you accept our &nbsp;
        <Link
          to="/privacy-policy"
          className=" text-black lg:text-white underline inline"
        >
          Privacy Policy
        </Link>
        .
      </CookieConsent>
      <div
        className={`mx-6 lg:mx-32 ${
          !isClosed && !isCookieSet ? "lg:pt-16" : ""
        }`}
      >
        <Header />
        {children}
      </div>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
