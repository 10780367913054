/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import {Helmet} from "react-helmet";
import {useStaticQuery, graphql} from "gatsby";

function SEO({description, lang, meta, title, type: _type, url}) {
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author

            baseUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const type = _type;
  let baseUrl = "https://venture-friends.vercel.app/";
  const image =
    "https://storage.googleapis.com/venture-friends-images/small_og_image_a311e3596b/small_og_image.png_small_og_image_a311e3596b.png?12397885.96500001";
  if (typeof window !== "undefined") {
    baseUrl = window.location.origin;
  }
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      // title={title}
      // titleTemplate={`%s | ${site.siteMetadata.title}`}
      // meta={[
      //   {
      //     name: `description`,
      //     content: metaDescription,
      //   },
      //   {
      //     property: `og:url`,
      //     content: baseUrl,
      //   },
      //   {
      //     property: `og:title`,
      //     content: title,
      //   },
      //   {
      //     property: `og:description`,
      //     content: metaDescription,
      //   },
      //   {
      //     property: `og:type`,
      //     content: type,
      //   },
      //   {
      //     property: `og:image`,
      //     content: site.siteMetadata.image,
      //   },
      //   {
      //     name: `twitter:card`,
      //     content: `summary`,
      //   },
      //   {
      //     name: `twitter:creator`,
      //     content: site.siteMetadata.author,
      //   },
      //   {
      //     name: `twitter:title`,
      //     content: title,
      //   },
      //   {
      //     name: `twitter:description`,
      //     content: metaDescription,
      //   },
      // ].concat(meta)}
    >
      <link rel="canonical" href={baseUrl} />
      <title>{title ? `${title} | VentureFriends` : "VentureFriends"} </title>
      <meta name="description" content={metaDescription} />
      <meta
        name="google-site-verification"
        content="ynB-KdbYwCMr6oLFmf674SCSBZRoYU1_3UOeDfqTeBw"
      />
      {image && <meta name="image" content={image} />}

      <meta property="og:url" content={baseUrl} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      {image && <meta property="og:image" content={image} />}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={site.siteMetadata.author} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      {image && <meta name="twitter:image" content={image} />}
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  type: `website`,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  url: PropTypes.string,
};

export default SEO;
